// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
// import LanguageMap from 'src/context/LanguageMap';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "home",
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: "contact",
    icon: <Iconify icon="ic:round-grain" />,
    path: PATH_PAGE.components,
  },
  {
    title: "about",
    icon: <Iconify icon="material-symbols:bookmark" />,
    path: PATH_PAGE.about,
  },
];

export default navConfig;
