import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _notifications } from '../../../_mock/arrays';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover/menupopover2';
import { IconButtonAnimate } from '../../../components/animate';
import StarIcon from '@mui/icons-material/Star';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState(null);

  const [notifications, setNotifications] = useState(_notifications);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButton
        color={openPopover ? 'warning' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 100, height: 40 }}
      >
        <Badge color="error" sx={{ color: "rgb(91, 229, 132)", fontSize: '13px' }}>
          Legal Advice
        </Badge>
      </IconButton>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Legal Advice</Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>

          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" sx={{ margin: '10px 10px' }}>Nothing posted or said on this channel is to be taken as any form of financial advice or endorsement. These posts are
              analytical and data centric, the intention is not for anyone to invest in any cryptocurrency mentioned here. Anyone
              considering investing in any cryptocurrency project should first undertake their own research before deciding whether or
              not to invest.

              It is also important to remember that when investing in cryptocurrencies your capital is at great risk due to lack of
              regulation and volatility. Cryptocurrencies are highly speculative in nature. All of this means that while the value of
              investments can rise, they can also fall.</Typography>
          </Box>

        </Scrollbar>

      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.node,
    type: PropTypes.string,
    title: PropTypes.string,
    isUnRead: PropTypes.bool,
    description: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date),
  }),
};

function NotificationItem({ notification }) {
  const { avatar } = renderContent(notification);

  // "favorites" içindeki öğelerin "name" kısmını alın
  const favoriteName = notification.name;

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{notification.logoSrc}</Avatar>
      </ListItemAvatar>

      {/* "favoriteName" değişkenini kullanarak ismi göster */}
      <ListItemText disableTypography primary={favoriteName} />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}

    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
