
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  position: null,
  userId: null,
  userData: null
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'POSITION') {
    return {
      ...state,
      position: action.payload.position,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'SET_USER_ID') {
    return {
      ...state,
      userId: action.payload.userId,
    };
  }
  if (action.type === 'SET_USER_DATA') {
    return {
      ...state,
      userData: action.payload.userData,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const getUserDetails = useCallback(async (userId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`http://localhost:8080/BATG/nonzero/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      const userData = response.data;

      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          userData,
        },
      });

    } catch (error) {
      console.error(error);
    }
  }, []);

  const initialize = useCallback(async (userId) => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const userResponse = await axios.get('http://localhost:8080/BATG/user', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });

        const user = userResponse.data;

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });

        dispatch({
          type: 'SET_USER_ID',
          payload: {
            userId: user.id,
          },
        });

        getUserDetails(user.id);
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }

  }, [storageAvailable, getUserDetails]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await axios.post('/api/auth/login', {
      email,
      password,
    });
    const { access_token, refresh_token } = response.data;
    // console.log("refresh",response.data.refresh_token)
    // console.log(response.data);

    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    setSession(access_token, refresh_token);
    dispatch({
      type: 'LOGIN',
      payload: {
        access_token,
        refresh_token,
      },
    });

    initialize();
    window.location.reload()
  }, [initialize]);

  // REGISTER
  const register = useCallback(async (email, password, userName, firstName, lastName) => {
    const response = await axios.post('http://localhost:8080/api/auth/register', {
      email,
      password,
      userName,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });

    try {
      const chatEngineUser = await axios.post(
        'https://api.chatengine.io/users/',
        {
          'username': userName,
          'secret': '1234567',
        },
        { headers: { 'PRIVATE-KEY': '076f35e1-5630-4b59-9aa7-ee7709bbb63d' } }
      );

    } catch (error) {
      console.error('Chatengine kayıt olamadı:', error);
    }
    window.location.href = '/auth/login';
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    if (accessToken) {
      try {
        await axios.get('http://localhost:8080/api/v1/auth/logout', {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
    setSession(null);
    if (storageAvailable) {
      localStorage.removeItem('accessToken');
    }

    dispatch({
      type: 'LOGOUT',
    });
  }, [storageAvailable]);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      register,
      userData: state.userData,
      logout,
      userId: state.userId
    }),
    [state.userData, state.userId, state.isAuthenticated, state.isInitialized, state.user, login, logout, register]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
