import PropTypes from 'prop-types';
// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import { LocalesContext } from './useLocales';
import { useContext } from 'react';

// ----------------------------------------------------------------------

ThemeLocalization.propTypes = {
  children: PropTypes.node,
};

export default function ThemeLocalization({ children }) {
  const outerTheme = useTheme();

  const { currentLang } = useContext(LocalesContext);

  const theme = createTheme(outerTheme, currentLang?.systemValue);

  return <ThemeProvider theme={theme}> {children} </ThemeProvider>;
}
