import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';

const CrossLines = ({ color }) => {

  const TrendingCoinsWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    backgroundColor: color,
    borderRadius: "10px",
    [theme.breakpoints.down('md')]: {
      minWidth: '100%', // Mobil için genişlik
      left: '0%',
      height: '2px',
    },

    [theme.breakpoints.up('md')]: {
      minWidth: '100%', // Tablet
      left: '0%',
      height: '2px',
    },

    [theme.breakpoints.only('lg')]: {
      minWidth: '80%', // Bilgisayar
      left: '10%',
      height: '3px',
    },

    [theme.breakpoints.only('xl')]: {
      minWidth: '60%', // Monitör
      left: '20%',
      height: '3px',
    },
  }));

  return <TrendingCoinsWrapper></TrendingCoinsWrapper>;
};

export default CrossLines;
