import useLocales2 from "src/locales/useLocales2";
export const map = {
    "en": {
        "home": "Home",
        "contact": "Contact",
        "about": "About Us",
        "trending-coins": "Trending Coins",
        "formatter-key": "en",
        "date-localizations-key": "en-US",
        "our-collaborations": "Our Collaborations",
        "table-column-name": "Name",
        "table-column-contract": "Contract",
        "table-column-change": "Change",
        "table-column-more": "More",
        "table-title__5m": "Trend 5 Minutes",
        "table-title__15m": "Trend 15 Minutes",
        "table-title__30m": "Trend 30 Minutes",
        "table-title__1h": "Trend 1 Hour",
        "table-title__whales": "Whales",
        "table-toggle__5m": "5m",
        "table-toggle__15m": "15m",
        "table-toggle__30m": "30m",
        "table-toggle__1h": "1h",
        "table-toggle__24h": "Daily",
        "table-buy-tx": "Buys",
        "table-copy-feedback-title": "Copied",
        "table-copy-feedback-subtitle": "Contract copied",
        "table-copy-feedback-confirm": "Okay",
        "table-expand-age": "Age",
        "table-expand-elapsed--less-than-a-minute": "Just Now",
        "table-expand-elapsed--second": "Sec",
        "table-expand-elapsed--minute": "Min",
        "table-expand-elapsed--hour": "Hr",
        "table-expand-elapsed--day": "Day",
        "table-expand-elapsed--month": "Mon",
        "table-expand-elapsed--year": "Yr",
        "table-expand-elapsed--old": "Old",
        "details-table-column-date": "Date",
        "details-table-column-type": "Type",
        "details-table-column-price": "Price",
        "details-table-column-total": "Value",
        "details-table-column-amount-token": "Amount Token",
        "details-table-column-amount-eth": "Amount",
        "details-table-column-maker": "Maker",
        "details-table-column-tx": "Tx",
        "details-table-value-date-am": "AM",
        "details-table-value-date-pm": "PM",
        "details-table-value-type-buy": "Buy",
        "details-table-value-type-sell": "Sell",
        "details-boxes-more-info": "More Info",
        "details-boxes-more-hidden": "Hidden",
        "details-boxes-price": "Price",
        "details-boxes-market-cap": "Market Cap",
        "details-boxes-liquidity": "Liquidity",
        "details-boxes-24h-volume": "Volume",
        "details-boxes-24h-buy": "24H Buy",
        "details-boxes-24h-sell": "24H Sell",
        "details-boxes-most-buy": "Most Buy",
        "details-boxes-most-sell": "Most Sell",
        "details-boxes-locked": "Locked",
        "details-boxes-locked-unknown": "Unknown",
        "details-boxes-unlocking-days": "Unlocking In",
        "details-boxes-owner": "Owner",
        "details-boxes-owner-renounced": "Renounced",
        "details-boxes-total-holders": "Total Holders",
        "details-boxes-tax": "Tax",
        "details-boxes-tax-buy": "Buy",
        "details-boxes-tax-sell": "Sell",
        "details-boxes-total-supply": "Total Supply",
        "details-boxes-initial-market-cap": "Initial M.Cap",
        "details-boxes-initial-liquidity": "Initial Liq.",
        "details-boxes-initial-price": "Initial Prc.",
        "contact-title": "Contact",
        "contact-text": "Please fill out the information to contact us.",
        "contact-text-2": "We will get back to you as soon as possible.",
        "contact-form-name": "Name",
        "contact-form-email": "Email",
        "contact-form-text": "Enter your message",
        "contact-form-submit": "Submit",
        "favorites-title": "Favorites",
        "information-title": "Information",
        "favorites-text": "You have {NUM} favorites",
        "about-pdf": "https://assets.dextrend.io/about-en.pdf",
        "information-text": `🔥 The numbers alongside flame emojis indicate the trend strength These scores represent their social media presence, contract reliability, and primarily showcase buyer power. \n🟦 Blue color represents the top of the trend lists.\n🟨 Yellow color indicates that the relevant token was created less than 24 hours ago.`,
        "details-boxes-pool-created-at": "Pool Creation",
        "details-boxes-pool-factory": "Pool Factory"
    },
    "tr": {
        "home": "Ana Sayfa",
        "about": "Hakkımızda",
        "contact": "İletişim",
        "trending-coins": "Trend Coinler",
        "formatter-key": "tr",
        "date-localizations-key": "tr-TR",
        "our-collaborations": "İşbirliklerimiz",
        "table-column-name": "Ad",
        "table-column-contract": "Sözleşme",
        "table-column-change": "Değişiklik",
        "table-column-more": "Daha Fazla",
        "table-title__5m": "5 Dakika Trendleri",
        "table-title__15m": "15 Dakika Trendleri",
        "table-title__30m": "30 Dakika Trendleri",
        "table-title__1h": "1 Saat Trendleri",
        "table-title__whales": "Balinalar",
        "table-toggle__5m": "5d",
        "table-toggle__15m": "15d",
        "table-toggle__30m": "30d",
        "table-toggle__1h": "1s",
        "table-toggle__24h": "Günlük",
        "table-buy-tx": "Alımlar",
        "table-copy-feedback-title": "Kopyalandı",
        "table-copy-feedback-subtitle": "Adres kopyalandı",
        "table-copy-feedback-confirm": "Tamam",
        "table-expand-age": "Yaş",
        "table-expand-elapsed--less-than-a-minute": "Şimdi",
        "table-expand-elapsed--second": "Sn",
        "table-expand-elapsed--minute": "Dk",
        "table-expand-elapsed--hour": "S",
        "table-expand-elapsed--day": "Gün",
        "table-expand-elapsed--month": "Ay",
        "table-expand-elapsed--year": "Yıl",
        "table-expand-elapsed--old": "Eski",
        "details-table-column-date": "Tarih",
        "details-table-column-type": "Tür",
        "details-table-column-price": "Fiyat",
        "details-table-column-total": "Toplam Değer",
        "details-table-column-amount-token": "Miktar (Jeton)",
        "details-table-column-amount-eth": "Miktar",
        "details-table-column-maker": "Üretici",
        "details-table-column-tx": "İşlem",
        "details-table-value-date-am": "ÖÖ",
        "details-table-value-date-pm": "ÖS",
        "details-table-value-type-buy": "Al",
        "details-table-value-type-sell": "Sat",
        "details-boxes-more-hidden": "Gizle",
        "details-boxes-price": "Fiyat",
        "details-boxes-market-cap": "Piyasa Değeri",
        "details-boxes-liquidity": "Likidite",
        "details-boxes-24h-volume": "24 Saat Hacmi",
        "details-boxes-24h-buy": "24 Saat Alım",
        "details-boxes-24h-sell": "24 Saat Satış",
        "details-boxes-most-buy": "En Fazla Alış",
        "details-boxes-most-sell": "En Fazla Satış",
        "details-boxes-locked": "Kilitli",
        "details-boxes-locked-unknown": "Bilinmiyor",
        "details-boxes-unlocking-days": "Açılış",
        "details-boxes-owner": "Sahibi",
        "details-boxes-owner-renounced": "Vazgeçildi",
        "details-boxes-total-holders": "Toplam Sahipler",
        "details-boxes-tax": "Vergi",
        "details-boxes-tax-buy": "Al",
        "details-boxes-tax-sell": "Sat",
        "details-boxes-total-supply": "Toplam Arz",
        "details-boxes-initial-market-cap": "Başl. P.Değeri",
        "details-boxes-initial-liquidity": "Başl. Lik.",
        "details-boxes-initial-price": "Başl. Fyt.",
        "contact-title": "İletişim",
        "contact-text": "Lütfen bize ulaşmak için bilgileri doldurun.",
        "contact-text-2": "En kısa sürede size geri döneceğiz.",
        "contact-form-name": "Ad",
        "contact-form-email": "E-posta",
        "contact-form-text": "Mesajınızı girin",
        "contact-form-submit": "Gönder",
        "favorites-title": "Favoriler",
        "information-title": "Bilgi",
        "favorites-text": "{NUM} favoriniz var",
        "about-pdf": "https://assets.dextrend.io/about-tr.pdf",
        "information-text": `🔥 Alev emojilerinin yanındaki sayılar trendin gücünü gösteriyor. Bu puanlar sosyal medyadaki varlığını, sözleşme güvenilirliğini temsil ediyor ve öncelikle alıcı gücünü gösteriyor."\n🟦 Mavi renk trend listelerinin zirvesini temsil eder.\n🟨 Sarı renk, ilgili tokenın 24 saatten daha kısa bir süre önce oluşturulduğunu gösterir.`,
        "details-boxes-pool-created-at": "Parite Tarihi",
        "details-boxes-pool-factory": "Parite Platformu"
    },
    "zh": {
        "home": "主页",
        "contact": "联系",
        "about": "关于我们",
        "trending-coins": "热门币种",
        "formatter-key": "zh",
        "date-localizations-key": "zh-CN",
        "our-collaborations": "我们的合作",
        "table-column-name": "名称",
        "table-column-contract": "合同",
        "table-column-change": "变化",
        "table-column-more": "更多",
        "table-title__5m": "5 分钟",
        "table-title__15m": "15 分钟",
        "table-title__30m": "30 分钟",
        "table-title__1h": "1 小时",
        "table-title__whales": "鲸鱼",
        "table-toggle__5m": "5分钟",
        "table-toggle__15m": "15分钟",
        "table-toggle__30m": "30分钟",
        "table-toggle__1h": "1小时",
        "table-toggle__24h": "每日",
        "table-buy-tx": "买入",
        "table-copy-feedback-title": "已复制",
        "table-copy-feedback-subtitle": "合同已复制",
        "table-copy-feedback-confirm": "好的",
        "table-expand-age": "年龄",
        "table-expand-elapsed--less-than-a-minute": "刚刚",
        "table-expand-elapsed--second": "秒",
        "table-expand-elapsed--minute": "分钟",
        "table-expand-elapsed--hour": "小时",
        "table-expand-elapsed--day": "天",
        "table-expand-elapsed--month": "月",
        "table-expand-elapsed--year": "年",
        "table-expand-elapsed--old": "老的",
        "details-table-column-date": "日期",
        "details-table-column-type": "类型",
        "details-table-column-price": "价格",
        "details-table-column-total": "价值",
        "details-table-column-amount-token": "代币数量",
        "details-table-column-amount-eth": "数量",
        "details-table-column-maker": "创建者",
        "details-table-column-tx": "交易",
        "details-table-value-date-am": "上午",
        "details-table-value-date-pm": "下午",
        "details-table-value-type-buy": "购买",
        "details-table-value-type-sell": "出售",
        "details-boxes-more-info": "更多信息",
        "details-boxes-price": "价格",
        "details-boxes-market-cap": "市值",
        "details-boxes-liquidity": "流动性",
        "details-boxes-24h-volume": "24小时交易量",
        "details-boxes-24h-buy": "24小时买入",
        "details-boxes-24h-sell": "24小时卖出",
        "details-boxes-most-buy": "最多买入",
        "details-boxes-most-sell": "最多卖出",
        "details-boxes-locked": "锁定",
        "details-boxes-locked-unknown": "未知",
        "details-boxes-unlocking-days": "解锁中",
        "details-boxes-owner": "所有者",
        "details-boxes-owner-renounced": "已放弃",
        "details-boxes-total-holders": "总持有者",
        "details-boxes-tax": "税收",
        "details-boxes-tax-buy": "买入",
        "details-boxes-tax-sell": "卖出",
        "details-boxes-total-supply": "总供应",
        "details-boxes-initial-market-cap": "初始市值",
        "details-boxes-initial-liquidity": "初始流动性",
        "details-boxes-initial-price": "初始价格",
        "contact-title": "联系我们",
        "contact-text": "请填写联系信息。",
        "contact-text2": "我们会尽快回复您。",
        "contact-form-name": "姓名",
        "contact-form-email": "电子邮件",
        "contact-form-text": "输入您的消息",
        "contact-form-submit": "提交",
        "favorites-title": "收藏夹",
        "favorites-text": "您有 {NUM} 个收藏",
        "about-pdf": "https://assets.dextrend.io/about-zh.pdf",
        "information-text": `🔥 ⽕焰表情符号旁边的数字表明了趋势强度。这些分数代表了他们的社交媒体存在、合同可靠性，并 且主要展⽰了买家的⼒量。\n🟦 蓝⾊代表趋势列表的顶部。\n🟨 ⻩⾊表⽰相关令牌是在不到 24 小时前创建的。`,
        "details-boxes-pool-created-at": "Pool Creation",
        "details-boxes-pool-factory": "Pool Factory"
    }
};
export default function LanguageMap(key) {
    const { currentLang } = useLocales2();
    return map[currentLang.value]?.[key];
}