import configLang from './config-lang';
import { createContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

let path = window.location.pathname;
path = path.split('/');
let defaultLang = configLang.defaultLang;
if (path.length > 1) {
  defaultLang = configLang.allLangs.find(lang => lang.value === path[1]) ?? defaultLang;
}
export const LocalesContext = createContext({ currentLang: defaultLang, allLangs: configLang.allLangs, setLang: (chainId) => { }, setLangByChainId: (chainıd) => { }, setLangByPath: () => { } });
export default function UseLocales({ children }) {
  const [currentLang, setCurrentLang] = useState(defaultLang);
  const navigate = useNavigate();
  const setLang = (lang) => {
    setCurrentLang(() => lang);
    navigate(`/${lang.name}`);
  }
  // const setLang = (searchBy) => {

  //   let filterKey = typeof searchBy === "number" ? "chainId" : "name";
  //   let filtered = configLang.allLangs.find(language => language[filterKey] === searchBy);
  //   if (!filtered) {
  //     if (!configLang.allLangs.length) {
  //       return;
  //     }
  //     navigate(`/${configLang.allLangs[0].name}`);
  //     return;
  //   }
  //   setCurrentLang(() => filtered);
  //   navigate(`/${filtered.name}`);
  // }
  const setLangByChainId = (chainId) => {
    setCurrentLang(() => configLang.allLangsMapped[chainId]);
  }
  const setLangByPath = () => {
    if (!configLang.allLangs.length) {
      return;
    }
    const path = window.location.pathname;
    let filtered;
    if (path === "/") {
      filtered = configLang.allLangs[0]
      navigate(`/${filtered.name}`);
    } else {
      var parts = path.split(/\//);
      filtered = configLang.allLangs.find(lang => lang.name === parts[1]);
      if (!filtered) {
        toast.error("Invalid chain!");
        console.log(parts, window.location.pathname);
        navigate("/404");
        return;
      }

    }
    setCurrentLang(() => filtered);
    return filtered;
  }
  return <LocalesContext.Provider value={{
    currentLang,
    setLang,
    translate: () => { },
    allLangs: configLang.allLangs,
    setLangByChainId,
    setLangByPath: setLangByPath,
  }}>
    {children}
  </LocalesContext.Provider>
}