import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Link, Card, TextField, InputAdornment } from '@mui/material';
import { FaMoon, FaSun, FaTelegramPlane, FaTwitter } from 'react-icons/fa';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import { RiTwitterXFill, RiGasStationFill } from "react-icons/ri";

// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
import { PATH_AUTH, PATH_DOCS, PATH_MINIMAL_ON_STORE } from '../../routes/paths';
// components
import Logo from '../../components/logo';
import LanguagePopover from '../dashboard/header/LanguagePopover';
import BnbPopover from '../dashboard/header/BnbPopover';
import Label from '../../components/label';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
// import { useLocales } from 'src/locales';
import CrossLines from 'src/pages/Crossline';
import { LocalesContext } from 'src/locales/useLocales';
import useLocales2 from 'src/locales/useLocales2';
import { WebSocketContext } from 'src/context/WebSocket';
import { useNavigate } from 'react-router';
import { SettingsContext } from 'src/components/settings/SettingsContext';

// ----------------------------------------------------------------------
function GasPrice({ gasPrice, style }) {
  const isDesktop = useResponsive('up', 'md');
  return <Box style={{ display: "flex", ...(style ?? {}) }}>
    <RiGasStationFill size={isDesktop ? 17 : 16} style={{ marginBlock: "auto" }} />
    <a style={{ marginBlock: "auto", marginLeft: 2, fontSize: isDesktop ? "15px" : "13px" }}>
      {gasPrice}
    </a>
  </Box>
}
export default function Header({ }) {
  const carouselRef = useRef(null);
  const { onToggleMode, themeMode } = useContext(SettingsContext);
  const { currentLang: currentChain, setLang } = useContext(LocalesContext);
  const { gasPrice } = useContext(WebSocketContext);
  const { currentLang } = useLocales2();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const search = (currentChain) => {
    setSearchText(text => {
      if (!text?.length) {
        navigate(`/${currentChain.name}/search`);
        return text;
      }
      navigate(`/${currentChain.name}/search?q=${text}`);
      return text;
    });
  }
  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  // const { currentLang: currentChain } = useLocales();
  const [lineColor, setLineColor] = useState("transparent");
  useEffect(() => {
    setLineColor(() => currentChain?.theme?.mainColor ?? "transparent");
    // #28B6D4   // ETH
  }, [currentChain]);

  return currentChain && (
    <>
      <AppBar ref={carouselRef} color="transparent" style={{ backgroundColor: !isDesktop ? "rgb(27 32 37)" : "transparent" }} sx={{ boxShadow: 0 }}>
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
              height: {
                md: HEADER.H_MAIN_DESKTOP - 16,
              },
            }),
          }}
        >
          <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
            <Logo />
            {!isDesktop && (currentChain.gasEnabled && gasPrice ? (<GasPrice gasPrice={gasPrice} style={{ marginLeft: 5, width: "30px" }} />) : (<div style={{ width: "35px", height: "1px" }}></div>))}
            <Box sx={{ marginLeft: { xs: 12, sm: 51, md: 0 }, marginRight: "-500px", display: 'flex' }} >
              {isDesktop ? (
                <TextField
                  label={
                    <div style={{ marginTop: '-2px' }}>
                      Search
                    </div>
                  }
                  onChange={(event) => {
                    if (!event?.currentTarget?.value) {
                      return;
                    }
                    setSearchText(() => event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key !== "Enter") {
                      return;
                    }
                    search(currentChain);
                  }}
                  variant="outlined"
                  fullWidth
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 2.5,
                    ml: 5,
                    mb: 2,
                    width: '200px',
                    '& .MuiInputBase-input': {
                      height: '15px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="white"
                          sx={{ marginTop: '4px' }}
                          onClick={() => {
                            search(currentChain);
                            // Arama işlemi burada gerçekleştirilebilir.
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <IconButton
                  color="white"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    navigate(`/${currentChain.name}/search`);
                    // Arama işlemi burada gerçekleştirilebilir.
                  }}
                >
                  <SearchIcon sx={{ color: 'white', width: 20, height: 20 }} />
                </IconButton>
              )}

              <Box sx={{ ml: { xs: 1, md: -1 }, mt: isDesktop ? '5px' : 0 }}>
                <LanguagePopover currentChain={currentChain} onChangeLang={setLang} /> {/*  allLangs içerisinden tıklanın dile ait elementin "chainId" değerini "onChangeLang"a gönderip manuel chain değiştir */}
              </Box>
              {isDesktop && currentChain.gasEnabled && gasPrice && <GasPrice gasPrice={gasPrice} />}

              {!isDesktop && (
                <NavMobile
                  isOffset={isOffset}
                  telegramText={"Telegram"}
                  xText={"Twitter"}
                  data={navConfig}
                  telegramIcon={<FaTelegramPlane size={20} style={{ marginLeft: '16px' }} />}
                  bnbPopover={<BnbPopover />}
                  xIcon={<RiTwitterXFill size={20} style={{ marginLeft: '16px' }} />}
                />
              )}
              {/* {!isDesktop && (
                <NavMobile isOffset={isOffset} text={"Twitter"} data={navConfig} telegramIcon={<FaTwitter size={20} style={{ marginLeft: '20px' }} onClick={() => {
                  window.open("https://twitter.com/dextrendio", "_blank", "noopener,noreferrer");
                }} />} bnbPopover={<BnbPopover />} />
              )} */}
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            {/* {isDesktop && <InformationPopover currentLang={currentLang.value} />} */}
            {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}
            {isDesktop && <BnbPopover />}
            {/* <IconButton
              color="white"
              sx={{ marginTop: '4px' }}
              onClick={(e) => {
                onToggleMode();
              }}
            >
            </IconButton> */}
            {isDesktop && (themeMode === "dark" ? <FaSun size={25} style={{ marginLeft: '15px', cursor: "pointer" }} onClick={() => {
              onToggleMode();
            }} /> : <FaMoon size={25} style={{ marginLeft: '15px', cursor: "pointer" }} onClick={() => {
              onToggleMode();
            }} />)}
            {isDesktop &&
              <FaTelegramPlane size={25} style={{ marginLeft: '15px', cursor: "pointer" }} onClick={() => {
                window.open("https://t.me/dextrendio", "_blank", "noopener,noreferrer");
              }} />
            }
            {isDesktop &&
              <RiTwitterXFill size={25} style={{ marginLeft: '15px', cursor: "pointer" }} onClick={() => {
                window.open("https://twitter.com/dextrendio", "_blank", "noopener,noreferrer");
              }} />
            }
          </Container>
        </Toolbar>

        {isOffset && <Shadow />}
        <Box
          style={{}}
        >
          <CrossLines color={lineColor} />
        </Box>

      </AppBar>
    </>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
