import { createContext, useEffect, useState, useContext } from "react"
import msgpack from "msgpack-lite";
import { Buffer } from "buffer"
import { useNavigate } from "react-router";
import useLocales2 from "src/locales/useLocales2";
import languageMap from "./LanguageMap";
// import { useLocales } from "src/locales";
// import { Socket } from "socket.io";
import { io as IO, Socket } from "socket.io-client"
import axios from "axios";
import { LocalesContext } from "src/locales/useLocales";
import ConfigLang from "src/locales/config-lang";
import { toast } from "react-toastify";
const server = "api.dextrend.io";
const api = `https://${server}`;
// const server = "127.0.0.1"; // TODO: TEST
// const api = `http://${server}`; // TODO: TEST
export const WebSocketContext = createContext();
export default function WebSocketProvider({ children }) {
  const [io, setIo] = useState(Socket.prototype);
  const [coinList, setCoinList] = useState([]);
  const [wsInitiated, setWsInitiated] = useState(false);
  const [whales, setWhales] = useState([]);
  const [favorites, setFavorites] = useState();
  const [mobileTopBanner, setMobileTopBanner] = useState();
  const [trendingIcon, setTrendingIcon] = useState();
  const [coinDetailsBanner, setCoinDetailsBanner] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [gasPrice, setGasPrice] = useState(0);
  const [trends, setTrends] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [coinData, setCoinData] = useState({});
  const [transactions, setTransactions] = useState({});
  const [coinStats, setCoinStats] = useState({});
  const [onConnect, setOnConnect] = useState(() => () => { });
  const { currentLang: currentChain, setLang, setLangByPath } = useContext(LocalesContext);
  const [showLogos, setShowLogos] = useState(true);
  useEffect(() => {
    setInterval(() => {
      // setShowLogos(showLogos => !showLogos); // TODO:
    }, 5000);
  }, []);

  const { allLangs, currentLang, } = useLocales2();
  const getTranslate = (key) => {
    return languageMap(key);
  }
  const requestSliders = async (currentChain) => {
    try {
      const request = await axios.get(currentChain.sliders);
      let data = request.data;
      const final = data.sliders?.map((v) => ({
        url: currentChain.sliders + v.id,
        caption: v.id + "",
        desktop: v.desktop,
        mobile: v.mobile,
        href: v.href
      }));
      setSliderImages(() => final ?? [])
    } catch (error) { }
  }
  const requestImage = async (key, mediaUrl) => {
    mediaUrl = (mediaUrl ?? "");
    mediaUrl = mediaUrl.endsWith("/") ? mediaUrl : (mediaUrl + "/");
    try {
      let response = await axios.get(`${mediaUrl}${key}`, {
        responseType: "arraybuffer", headers: {
          // "Access-Control-Request-Headers": "redirect, Redirect, extra"
        }
      });
      const base64 = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      // console.log(response.headers)
      return {
        href: response.headers.redirect,
        image: base64,
        extra: response.headers.extra ?? "DEXT"
      };
    } catch (e) {

    }
    return { image: null, href: "/", extra: "DEXT" };
  }
  const requestPairDetails = async (chainId, pairAddress) => {
    const response = (await axios.get(`${api}/v1/chains/${chainId}/${pairAddress}`)).data;
    if (response.code !== 1) {
      toast.error(response.message);
      return;
    }
    return response.response;
  }
  const subscribeSwaps = async (io, pairAddress, callback) => {
    io.emit("subscribe_swaps", pairAddress);
    io.on("swap", (data) => {
      if (!new RegExp(pairAddress, "i").test(data.pairAddress)) {
        return;
      }
      callback(data);
    })
  };
  const search = async (chainId, query) => {
    try {
      const response = (await axios.get(`${api}/v1/chains/${chainId}/search?q=${query}`)).data;
      if (response.code !== 1) {
        toast.error(response.message);
        return [];
      }
      return response.response;
    } catch (error) {
      toast.error(error.response.message);
      return [];
    }
  };
  const requestSwaps = async (chainId, pairAddress) => {
    const response = (await axios.get(`${api}/v1/chains/${chainId}/${pairAddress}/swaps`)).data;
    if (response.code !== 1) {
      toast.error(response.message);
      return [];
    }
    return response.response;
  }
  const $setTrends = (trends) => {
    setTrends(() => trends);
  }
  const $setWhales = (whales) => {
    setWhales(() => whales);
  }
  const requestTrends = async (chainId) => {
    const start = Date.now();
    const response = (await axios.get(`${api}/v1/chains/${chainId}/trends?whales=true`)).data;
    if (!response.code === 1) {
      toast.error(response.message);
      return;
    }
    $setTrends(response.response.trends);
    $setWhales(response.response.whales);

  }
  useEffect(() => {
    if (!currentChain) {
      return;
    }
    setGasPrice(() => currentChain.gasPrice ?? 0);

    setIo(io => {
      try {
        io?.disconnect();
      } catch (error) { }
      io = connect(currentChain.chainId, true);
      return io;
    });
    // setIo(io => {
    //   io.io.uri = server + "/" + currentChain.chainId;
    //   try {
    //     if (io.connected) {
    //       io.disconnect();
    //       io.connect();
    //     }
    //   } catch (e) {
    //   }
    //   return io;
    // })
  }, [currentChain]);
  const connect = (chainId, _return = false) => {
    const start = Date.now();
    // setWsInitiated(() => true)
    // let url = localStorage.getItem("ws");
    console.log("connecting", `${server}/${chainId}`, "path is /io")
    let io = IO(`${server}/${chainId}`, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 5000,
      path: "/io"
    });
    const interval = setInterval(() => {
      try {
        io.send({ type: "ping" });
      } catch (error) { }
    }, 1000 * 15);
    io.on("connect", (listener) => {
      console.log("Connected in", Date.now() - start, "ms", listener?.handshake?.headers?.host); // TODO: TEST
    });
    io.on("trends_update", data => {
      $setTrends(data);
    })
    io.on("gas_price_change", data => {
      setGasPrice(() => data.gasPrice);
    });
    io.on("whale_swap", data => {
      setWhales(whales => {
        whales.unshift(data);
        return whales;
      });
    });
    io.on("error", response => {
      console.error('error', response);
    })
    if (_return) {
      return io;
    }
    setIo(() => io);
  }
  useEffect(() => {
    if (!favorites) return;
    localStorage.setItem("favorites", JSON.stringify(favorites));
  }, [favorites]);
  useEffect(() => {
    let favorites = localStorage.getItem("favorites");
    setFavorites(() => favorites ? JSON.parse(favorites) : []);
  }, []);
  useEffect(() => {
    if (!currentChain) return;
    requestTrends(currentChain.chainId);
    requestImage("trending-icon", currentChain.mediaGeneral).then(response => setTrendingIcon(response));
    requestImage("mobile-top", currentChain.media).then(response => setMobileTopBanner(response));
    requestImage("detail-1", currentChain.media).then(response => setCoinDetailsBanner(response));
    // axios.get(`${currentChain.http}api/v1/coins`).then(response => {
    //   setCoinDisplay(() => response.data.displayedItems);
    //   setWhales(() => response.data.whaleTransactions);
    // });
    requestSliders(currentChain);
  }, [currentChain]);
  useEffect(() => {
    if (!currentChain || !currentChain.gasEnabled || !gasPrice) {
      return;
    }
    const ls = localStorage.getItem("chains");
    if (ls) {
      let parsed = JSON.parse(ls);
      parsed.langs = parsed.langs.map(lang => {
        if (lang.chainId === currentChain.chainId) {
          lang.gasPrice = gasPrice;
        }
        return lang;
      });
      localStorage.setItem("chains", JSON.stringify(parsed));
    }
  }, [gasPrice, currentChain]);
  useEffect(() => {
    const ls = localStorage.getItem("chains");
    if (ls) {
      const parsed = JSON.parse(ls);
      if (parsed.updatedAt >= Date.now() - (1000 * 60 * 60 * 2)) {
        ConfigLang.setLangs(parsed.langs);
        console.log('[localstorage] chains:', ConfigLang.allLangs);
        if (ConfigLang.allLangs.length) {
          setLangByPath();
          return;
        }
      }
    }
    axios.get(`${api}/v1/chains`).then(response => {
      ConfigLang.setLangs(response.data.response.chains);
      localStorage.setItem("chains", JSON.stringify({ langs: ConfigLang.allLangs, updatedAt: Date.now() }));
      console.log('[request] chains:', ConfigLang.allLangs);
      if (ConfigLang.allLangs.length) {
        setLangByPath();
      }
    });
  }, []);
  return (
    <WebSocketContext.Provider value={{
      getTranslate,
      io,
      trends,
      coinData,
      transactions,
      coinStats,
      requestPairDetails,
      requestSwaps,
      subscribeSwaps,
      search,
      whales, setWhales,
      favorites, setFavorites,
      onConnect, setOnConnect,
      requestImage,
      mobileTopBanner,
      showLogos,
      trendingIcon,
      coinDetailsBanner,
      currentChain,
      isLoading, setIsLoading,
      gasPrice,
      sliderImages
    }}>
      {children}
    </WebSocketContext.Provider>
  )
}
// export function useContext() {
//     // const context = useContext(WebSocketContext);
//     return {ws: WebSocket.prototype, coinList: [], coinDisplay: []};
// }