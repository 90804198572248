import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { m, useScroll } from 'framer-motion';
import { green } from '@mui/material/colors';
import SortIcon from '@mui/icons-material/Sort';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Stack, AppBar, Toolbar, IconButton, Typography, Divider, Paper, Box, Tooltip, useMediaQuery } from '@mui/material';
import OnlineIcon from '@mui/icons-material/OnlinePrediction';
import { SiSaturn } from 'react-icons/si'
// utils
import { bgBlur, textGradient, bgGradient } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';

// config
import { HEADER, NAV } from '../../../config-global';

// components
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';
import { useAuthContext } from '../../../auth/useAuthContext';

// ----------------------------------------------------------------------

const StyledGradientTextNonZero = styled(m.h1)(({ theme }) => ({
  ...textGradient(
    `300deg, ${theme.palette.primary.main} 0%, ${theme.palette.warning.main} 25%, ${theme.palette.primary.main} 50%, ${theme.palette.warning.main} 75%, ${theme.palette.primary.main} 100%`
  ),
  backgroundSize: '400%',
  fontFamily: "'Barlow', sans-serif",
  fontSize: `${64 / 16}rem`,
  textAlign: 'center',
  lineHeight: 1,
  padding: 0,
  marginTop: 8,
  marginBottom: 24,
  letterSpacing: 2,
  fontWeight: 700,
  [theme.breakpoints.up('md')]: {
    fontSize: `${28 / 16}rem`,
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { userId } = useAuthContext();
  const { themeLayout } = useSettingsContext();
  const [position, setPosition] = useState({});
  const [onlineCount, setOnlineCount] = useState(0);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
      >
        {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Typography
          variant="body1"
          component={StyledGradientTextNonZero}
          sx={{
            color: green[500],
            fontWeight: 900,
            '@media screen and (min-width: 1630px)': {
              marginLeft: '300px'
            }
          }}
          animate={{ backgroundPosition: '200% center' }}
          transition={{
            repeatType: 'reverse',
            ease: 'linear',
            duration: 20,
            repeat: Infinity,
          }}
        >
          nonZeroAcademia
        </Typography>

      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        {!matches ? (
          <Paper elevation={3} sx={{ p: 0.5, display: 'flex', alignItems: 'center', bgcolor: 'background.paper' }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Tooltip title="nonRank">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <EmojiEventsIcon sx={{ color: green[500] }} />
                  <Typography variant="body1">{position.nonRank}</Typography>
                </Box>
              </Tooltip>
              <Divider orientation="vertical" flexItem />
              <Tooltip title="nonPosition">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SortIcon sx={{ color: green[500] }} />
                  <Typography variant="body1">{position.position}.</Typography>
                </Box>
              </Tooltip>
              <Divider orientation="vertical" flexItem />
              <Tooltip title="nonOnline">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <SiSaturn style={{ color: green[500] }} size={30} />
                  <Typography variant="body1">{onlineCount}</Typography>
                </Box>
              </Tooltip>
              <Divider orientation="vertical" flexItem />

              <AccountPopover />
            </Stack>
          </Paper>
        ) : (
          <AccountPopover />
        )}

      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
