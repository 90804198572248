import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { List, Drawer, IconButton, Box, Divider, Typography } from '@mui/material'; // Divider ekledik
// config
import { NAV } from '../../../../config-global';
// components
import Logo from '../../../../components/logo';
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';
//
import NavList from './NavList';
import useLocales2 from 'src/locales/useLocales2';
import { FaMoon, FaSun } from 'react-icons/fa';
import { SettingsContext } from 'src/components/settings/SettingsContext';

// ----------------------------------------------------------------------

NavMobileTelegram.propTypes = {
  data: PropTypes.array,
  isOffset: PropTypes.bool,
  telegramIcon: PropTypes.element,
  bnbPopover: PropTypes.element,
};

export default function NavMobileTelegram({ isOffset, data, telegramIcon, bnbPopover, text, xIcon, telegramText, xText }) {
  const { pathname } = useLocation();
  const { currentLang } = useLocales2()
  const { themeMode, onToggleMode } = useContext(SettingsContext);
  // console.log(currentLang)
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          mr: 1,
          ...(isOffset && {
            color: 'text.primary',
          })
        }}
      >
        <Iconify icon="eva:menu-2-fill" style={{ width: '20px', height: '20px', color: 'white' }} />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', ml: 1.2 }}>
            {bnbPopover}
            <Typography variant="body2" sx={{ fontSize: '14px', color: theme => theme.palette.text.secondary }}>{currentLang.label}</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', ml: 1.2, marginTop: "15px" }} onClick={() => {
            onToggleMode();
          }}>
            {(themeMode === "dark" ? <FaSun size={20} style={{ marginLeft: '9px', marginRight: "9px", cursor: "pointer" }} /> : <FaMoon size={20} style={{ marginLeft: '9px', marginRight: "9px", cursor: "pointer" }} />)}
            <Typography variant="body2" sx={{ fontSize: '14px', color: theme => theme.palette.text.secondary }}>{themeMode === "dark" ? "Dark" : "Light"}</Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', mt: 2 }} onClick={() => {
            window.open("https://t.me/dextrendio", "_blank", "noopener,noreferrer");
          }}>
            {telegramIcon}
            <Typography variant="body2" sx={{ fontSize: '14px', color: theme => theme.palette.text.secondary }}>{telegramText}</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px', mt: 2 }} onClick={() => {
            window.open("https://twitter.com/dextrendio", "_blank", "noopener,noreferrer");
          }}>
            {xIcon}
            <Typography variant="body2" sx={{ fontSize: '14px', color: theme => theme.palette.text.secondary }}>{xText}</Typography>
          </Box>

        </Scrollbar>
      </Drawer>
    </>
  );
}