import { useContext, useState } from 'react';
// @mui
import { MenuItem, Stack, Card } from '@mui/material';
// locales
// import { useLocales } from '../../../locales';
// components
import Image from '../../../components/image';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useResponsive from 'src/hooks/useResponsive';
import { useNavigate } from 'react-router';
import configLang from 'src/locales/config-lang';
import { LocalesContext } from 'src/locales/useLocales';

// ----------------------------------------------------------------------

export default function LanguagePopover({ currentChain, onChangeLang }) {
  const contextValues = useContext(LocalesContext);
  // console.log("LanguagePopover.js", { contextValues });
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState(null);
  const isDesktop = useResponsive('up', 'md');
  const isMobile = !isDesktop;
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangeLang = (newLang) => {
    // navigate(`/${newLang}`);fl
    onChangeLang(newLang);
    handleClosePopover();
  };

  const iconButtonWidth = isMobile ? 60 : 60;
  const iconButtonHeight = isMobile ? 35 : 40;
  const cardMargin = isMobile ? 0 : '20px';
  const popoverWidth = isMobile ? 110 : 180;

  const menuItemStyle = {
    fontSize: isMobile ? '10px' : undefined,
  };

  const imageStyle = {
    width: isMobile ? 28 : 28,
    marginRight: isMobile ? 1 : 2,
  };

  const cardBackgroundColor = currentChain?.value === 'bsc' ? '#F3BA30' : currentChain?.value === 'eth' ? '#1E4554' : '';

  return (
    <>
      <Card sx={{ margin: cardMargin, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: cardBackgroundColor, }}>
        <IconButtonAnimate
          onClick={handleOpenPopover}
          sx={{
            width: iconButtonWidth,
            height: iconButtonHeight,
            ...(openPopover && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <Image disabledEffect src={currentChain.icon} alt={currentChain.alias} />
          {openPopover ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButtonAnimate>

        <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: popoverWidth }}>
          <Stack spacing={0.2}>
            {configLang.allLangs.map((option) => (
              <MenuItem
                key={option.name}
                selected={option.name === currentChain.name}
                onClick={() => handleChangeLang(option)}
                style={menuItemStyle}
              >
                <Image
                  disabledEffect
                  alt={option.alias}
                  src={option.icon}
                  sx={imageStyle}
                />

                {option.alias}
              </MenuItem>
            ))}
          </Stack>
        </MenuPopover>
      </Card>
    </>
  );
}
