// @mui
import { enUS, frFR, zhCN, viVN, arSA } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

// export const allLangs = [
//   {
//     label: 'ETHEREUM',
//     value: 'eth',
//     hidden: false,
//     ws: "https://api-eth.dextrend.io",
//     http: "https://api-eth.dextrend.io/http/",
//     // ws: "http://127.0.0.1",
//     // http: "http://127.0.0.1/http/",
//     systemValue: zhCN,
//     chainCoin: "ETH",
//     scanner: "https://etherscan.io/",
//     media: "https://media.dextrend.io/media/eth/",
//     mediaGeneral: "https://media.dextrend.io/media/general/",
//     dext: "https://www.dextools.io/app/en/ether/pair-explorer/",
//     // dextChart: "https://www.dextools.io/widget-chart/en/ether/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
//     dextChart: "https://dexscreener.com/ethereum/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
//     logoSrc: "https://www.dextools.io/resources/tokens/logos/ether/",
//     icon: '/assets/icons/eth.png',
//     icon_sub: "/assets/icons/eth-r.png",
//     icon_scanner: '/assets/icons/etherscan.svg',
//   },
//   {
//     label: 'BNB CHAIN',
//     value: 'bsc',
//     hidden: false,
//     ws: "https://api-bsc.dextrend.io",
//     http: "https://api-bsc.dextrend.io/http/",
//     // ws: "http://127.0.0.1:80",
//     // http: "http://127.0.0.1:80/http/",
//     chainCoin: "BNB",
//     systemValue: viVN,
//     scanner: "https://bscscan.com/",
//     media: "https://media.dextrend.io/media/bsc/",
//     mediaGeneral: "https://media.dextrend.io/media/general/",
//     dext: "https://www.dextools.io/app/en/bnb/pair-explorer/",
//     // dextChart: "https://www.dextools.io/widget-chart/en/bnb/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
//     dextChart: "https://dexscreener.com/bsc/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
//     logoSrc: "https://www.dextools.io/resources/tokens/logos/bsc/",
//     icon: '/assets/icons/bsc.png',
//     icon_sub: "/assets/icons/bsc-r.png",
//     icon_scanner: '/assets/icons/bscscan.svg',
//   },
//   // {
//   //   label: 'BNB CHAIN-2',
//   //   value: 'bsc-test',
//   //   hidden: true,
//   //   // ws: "wss://api.dextrend.io/ws/bsc-test",
//   //   ws: "ws://127.0.0.1:80",
//     // chainCoin: "BNB",
//     //   systemValue: viVN,
//   //   scanner: "https://bscscan.com/",
//   //   media: "https://dextrend.io/media/bsc/",
//   //   dext: "https://www.dextools.io/app/en/bnb/pair-explorer/",
//   //   // dextChart: "https://www.dextools.io/widget-chart/en/bnb/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
//   //   dextChart: "https://dexscreener.com/bsc/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
//   //   logoSrc: "https://www.dextools.io/resources/tokens/logos/bsc/",
//   //   icon: '/assets/icons/bsc.png',
//   //   icon_sub: "/assets/icons/bsc-r.png",
//   //   icon_scanner: '/assets/icons/bscscan.svg',
//   // },
//   // {
//   //   label: 'ETHEREUM-2',
//   //   value: 'eth-test',
//   //   hidden: false,
//     // chainCoin: "ETH",
//     //   // ws: "wss://api.dextrend.io",
//   //   ws: "ws://127.0.0.1",
//   //   systemValue: zhCN,
//   //   scanner: "https://etherscan.io/",
//   //   media: "https://dextrend.io/media/eth/",
//   //   dext: "https://www.dextools.io/app/en/ether/pair-explorer/",
//   //   // dextChart: "https://www.dextools.io/widget-chart/en/ether/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
//   //   dextChart: "https://dexscreener.com/ethereum/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
//   //   logoSrc: "https://www.dextools.io/resources/tokens/logos/ether/",
//   //   icon: '/assets/icons/eth.png',
//   //   icon_sub: "/assets/icons/eth-r.png",
//   //   icon_scanner: '/assets/icons/etherscan.svg',
//   // },
// ];
class $ {
  $allLangs = [
    // {
    //   label: 'ETHEREUM',
    //   chainId: 1,
    //   value: 'eth',
    //   hidden: false,
    //   systemValue: zhCN,
    //   scanner: "https://etherscan.io/",
    //   media: "https://media.dextrend.io/media/eth/",
    //   sliders: "https://media.dextrend.io/sliders/",
    //   mediaGeneral: "https://media.dextrend.io/media/general/",
    //   dext: "https://www.dextools.io/app/en/ether/pair-explorer/",
    //   // dextChart: "https://www.dextools.io/widget-chart/en/ether/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
    //   dextChart: "https://dexscreener.com/ethereum/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
    //   logoSrc: "https://www.dextools.io/resources/tokens/logos/ether/",
    //   icon: '/assets/icons/eth.png',
    //   icon_sub: "/assets/icons/eth-r.png",
    //   icon_scanner: '/assets/icons/etherscan.png',
    // },
    // {
    //   label: 'ARBITRUM',
    //   chainId: 42161,
    //   value: 'arb',
    //   hidden: false,
    //   // ws: "https://api-arb.dextrend.io",
    //   // http: "https://api-arb.dextrend.io/http/",
    //   hideGasPrice: true,
    //   systemValue: zhCN,
    //   scanner: "https://arbiscan.io/",
    //   media: "https://media.dextrend.io/media/eth/",
    //   sliders: "https://media.dextrend.io/sliders/",
    //   mediaGeneral: "https://media.dextrend.io/media/general/",
    //   dext: "https://www.dextools.io/app/en/arb/pair-explorer/",
    //   // dextChart: "https://www.dextools.io/widget-chart/en/ether/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
    //   dextChart: "https://dexscreener.com/arbitrum/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
    //   logoSrc: "https://www.dextools.io/resources/tokens/logos/arbitrum/",
    //   icon: '/assets/icons/arb.png',
    //   icon_sub: "/assets/icons/arb-r.png",
    //   icon_scanner: '/assets/icons/arbiscan.png',
    // },
    // {
    //   label: 'BNB CHAIN',
    //   chainId: 57,
    //   value: 'bsc',
    //   hidden: false,
    //   systemValue: viVN,
    //   scanner: "https://bscscan.com/",
    //   media: "https://media.dextrend.io/media/bsc/",
    //   sliders: "https://media.dextrend.io/sliders/",
    //   mediaGeneral: "https://media.dextrend.io/media/general/",
    //   dext: "https://www.dextools.io/app/en/bnb/pair-explorer/",
    //   // dextChart: "https://www.dextools.io/widget-chart/en/bnb/pe-light/{PAIRADDRESS}?theme=dark&chartType=1&chartResolution=60&drawingToolbars=true",
    //   dextChart: "https://dexscreener.com/bsc/{PAIRADDRESS}?embed=1&theme=dark&trades=0&info=0",
    //   logoSrc: "https://www.dextools.io/resources/tokens/logos/bsc/",
    //   icon: '/assets/icons/bsc.png',
    //   icon_sub: "/assets/icons/bsc-r.png",
    //   icon_scanner: '/assets/icons/bscscan.png',
    // }
  ];
  allLangsMapped = {};
  extra = {
    0: {
      icon: "/assets/icons/all.png",
      iconClickable: "/assets/icons/all-r.png",
      sliders: "https://media.dextrend.io/sliders/",
      media: "https://media.dextrend.io/media/general/",
      mediaGeneral: "https://media.dextrend.io/media/general/",
      theme: {
        mainColor: "#fff"
      }
    },
    1: {
      icon: "/assets/icons/eth.png",
      iconClickable: "/assets/icons/eth-r.png",
      chart: {
        embedUrl: "https://dexscreener.com/ethereum/{PA}?embed=1&theme=dark&trades=0&info=0"
      },
      coins: {
        logo: {
          src: "https://cdn.dextrend.io/logos/eth/{CA}.png"
        }
      },
      explorer: {
        address: "https://etherscan.io",
        icon: "/assets/icons/etherscan.png"
      },
      sliders: "https://media.dextrend.io/sliders/",
      media: "https://media.dextrend.io/media/eth/",
      mediaGeneral: "https://media.dextrend.io/media/general/",
      theme: {
        mainColor: "#28B6D4"
      }
    },
    56: {
      icon: "/assets/icons/bsc.png",
      iconClickable: "/assets/icons/bsc-r.png",
      chart: {
        embedUrl: "https://dexscreener.com/bsc/{PA}?embed=1&theme=dark&trades=0&info=0"
      },
      coins: {
        logo: {
          src: "https://cdn.dextrend.io/assets/bsc/{CA}.png"
        }
      },
      explorer: {
        address: "https://bscscan.com",
        icon: "/assets/icons/bscscan.png"
      },
      sliders: "https://media.dextrend.io/sliders/",
      media: "https://media.dextrend.io/media/bsc/",
      mediaGeneral: "https://media.dextrend.io/media/general/",
      theme: {
        mainColor: "yellow"
      }
    },
    42161: {
      icon: "/assets/icons/arb.png",
      iconClickable: "/assets/icons/arb-r.png",
      chart: {
        embedUrl: "https://dexscreener.com/arbitrum/{PA}?embed=1&theme=dark&trades=0&info=0"
      },
      coins: {
        logo: {
          src: "https://cdn.dextrend.io/assets/arb/{CA}.png"
        }
      },
      explorer: {
        address: "https://arbiscan.io",
        icon: "/assets/icons/arbiscan.png"
      },
      sliders: "https://media.dextrend.io/sliders/",
      media: "https://media.dextrend.io/media/arb/",
      mediaGeneral: "https://media.dextrend.io/media/general/",
      theme: {
        mainColor: "transparent"
      }
    },
    8453: {
      icon: "/assets/icons/base.png",
      iconClickable: "/assets/icons/arb-r.png",
      chart: {
        embedUrl: "https://dexscreener.com/arbitrum/{PA}?embed=1&theme=dark&trades=0&info=0"
      },
      coins: {
        logo: {
          src: "https://cdn.dextrend.io/assets/arb/{CA}.png"
        }
      },
      explorer: {
        address: "https://arbiscan.io",
        icon: "/assets/icons/arbiscan.png"
      },
      sliders: "https://media.dextrend.io/sliders/",
      media: "https://media.dextrend.io/media/arb/",
      mediaGeneral: "https://media.dextrend.io/media/general/",
      theme: {
        mainColor: "#7986CB"
      }
    }
  }
  // defaultLang = this.allLangs[0]
  // setDefaultLang(value) {
  //   this.defaultLang = this.allLangs.find(lang => lang.value === value) ?? this.allLangs[0];
  // }
  get allLangs() {
    return this.$allLangs;
  }

  getLang(value) {
    return this.allLangsMapped[value];
  }

  setLangs(langs) {
    langs = langs.map(lang => {
      lang = { ...lang, ...(this.extra[lang.chainId] ?? {}) }
      return lang;
    })
    this.$allLangs = langs;
    this.allLangsMapped = langs.reduce((previous, current) => {
      previous[current.name] = current;
      previous[current.chainId] = current;
      return previous;
    }, {});
  }
}

export default new $();
// export let defaultLang = allLangs[0]; // English
