import { Navigate, useNavigate, useParams, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import MainLayout from '../layouts/main';
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // // Auth
  // LoginPage,
  // RegisterPage,
  // VerifyCodePage,
  // NewPasswordPage,
  // ResetPasswordPage,
  // // Dashboard: General
  // GeneralBookingPage,
  // // Dashboard: User
  // UserProfilePage,
  // UserAccountPage,
  // // Dashboard: Ecommerce
  // EcommerceShopPage,
  // MailPage,
  // EcommerceProductDetailsPage,
  // EcommerceProductDetailsPage2,
  // Dashboard: App
  // ChatPage,
  //
  // Page500,
  // Page403,
  Page404,
  HomePage,
  SearchPage,
  // FaqsPage,
  AboutPage,
  Contact,
  MaintenancePage,
  //
  ComponentsOverviewPage,
  CoinPage
} from './elements';
import { useAuthContext } from '../auth/useAuthContext';
import { useState, useEffect, useContext } from 'react';
import msgpack from "msgpack-lite";
import uuidv4 from 'src/utils/uuidv4';
import { TMP } from "./tmp";
import WebSocketProvider from 'src/context/WebSocket';
// import { useLocales } from 'src/locales';
import configLang from 'src/locales/config-lang';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import UseLocales, { LocalesContext } from 'src/locales/useLocales';
import LoadingScreen from 'src/components/loading-screen';
// ----------------------------------------------------------------------
function Navigator({ }) {
  const contextValues = useContext(LocalesContext);
  const currentChain = contextValues.currentChain;
  // console.log('routes/index.js', { contextValues });
  const navigate = useNavigate();
}
export default function Router() {
  const { userId } = useAuthContext();
  // const { currentLang } = useLocales();
  return (
    // <LanguageProvider>
    <UseLocales>
      <WebSocketProvider>
        {/* <LoadingScreen/> */}
        {
          useRoutes([
            // Main Routes
            {
              element: <MainLayout />,
              children: [
                { element: <Navigator />, index: true },
                { path: 'contact-us', element: <Contact /> },
                { path: 'about-us', element: <AboutPage /> },
                {
                  path: 'contact',
                  children: [
                    { element: <ComponentsOverviewPage />, index: true },
                  ],
                },
                {
                  path: "/",
                  element: <HomePage />,
                },
                {
                  path: "/:chainName",
                  element: <HomePage />,
                },
                {
                  path: "/:chainName/search",
                  element: <SearchPage />,
                },
                {
                  path: "/:chainName/pairs/:pairAddress",
                  element: <CoinPage />,
                },
              ]/*.concat(configLang.allLangs.reduce((previous, lang) => {
                previous.push({ path: `/${lang.value}`, element: <HomePage chainValue={lang.value} /> });
                previous.push({ path: `/${lang.value}/pairs/:name`, element: <CoinPage chainValue={lang.value} /> })
                return previous;
              }, []))
              */
            },
            {
              element: <CompactLayout />,
              children: [
                { path: 'maintenance', element: <MaintenancePage /> },
                // { path: '500', element: <Page500 /> },
                { path: '404', element: <Page404 /> },
                // { path: '403', element: <Page403 /> },
              ],
            },
            { path: '*', element: <Navigate to="/404" replace /> },
          ])
        }
      </WebSocketProvider>
    </UseLocales>
    // </LanguageProvider>
  )
}
