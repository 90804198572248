// @mui
import { enUS, frFR, zhCN, viVN, arSA, trTR } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  // {
  //   label: 'Türkçe',
  //   value: 'tr',
  //   systemValue: trTR,
  //   icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/1200px-Flag_of_Turkey.svg.png',
  // },
  {
    label: '中文',
    value: 'zh',
    systemValue: zhCN,
    icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Flag_of_China.png/800px-Flag_of_China.png?20091111204850',
  },
];

export const defaultLang = allLangs[0]; // English
