import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer';
import Header from './Header';
import { ToastContainer } from 'react-toastify';
import useResponsive from 'src/hooks/useResponsive';
import { useContext } from 'react';
import { LocalesContext } from 'src/locales/useLocales';
import { SettingsContext } from 'src/components/settings/SettingsContext';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const contextValues = useContext(LocalesContext);
  // console.log('MainLayout.js', {contextValues});
  const isHome = pathname === '/';
  const isDesktop = useResponsive('up', 'md');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header/>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        {
          isDesktop ?
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover={false}
              theme="dark"
              toastStyle={{ backgroundColor: "#21272f", borderRadius: "5px" }}
            /> :
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              pauseOnHover={false}
              theme="dark"
              toastStyle={{ backgroundColor: "#21272f", width: "50%", marginLeft: "auto", borderRadius: "5px" }}
            />
        }
        <Outlet />
      </Box>

      <Footer />
    </Box>
  );
}
