import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';

// _mock
import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/logo';
import InformationPopover from '../../layouts/dashboard/header/InformationPopover'
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Minimal',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@minimals.cc', href: '#' },
      { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';
  const isDesktop = useResponsive('up', 'md');

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 2,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Box style={{}}>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div" style={{ marginInline: "auto" }}>
          © All rights reserved
          <br />DexTrend.io
          <br />
        </Typography>
        <InformationPopover />
      </Box>
    </Box>
  );

  return simpleFooter;
}
