// routes
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { BiSolidCompass, BiSolidBookAlt } from "react-icons/bi"
import { ImBook } from "react-icons/im"
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    items: [

      { title: 'nonDashboard', path: PATH_DASHBOARD.user.root, icon: <DashboardIcon /> },
      { title: 'nonExplore', path: PATH_DASHBOARD.general.booking, icon: <BiSolidCompass size={30} /> },
      {
        title: 'nonMyCapsule',
        path: PATH_DASHBOARD.eCommerce.shop,
        icon: <ImBook size={20} />,
      },
      {
        title: 'nonCapsule',
        path: PATH_DASHBOARD.mail.all,
        icon: ICONS.cart,
      },

      {
        title: 'nonTopUser',
        path: PATH_DASHBOARD.calendar,
        icon: <EmojiEventsIcon />,
      },
      {
        title: 'nonChat',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },

    ],
  },
];

export default navConfig;
