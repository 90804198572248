import { useState } from "react";

export class TMP {
    static get = null;
    static assigned = false;
    static set = () => { };
    static assign(get, set) {
        if (this.assigned) return;
        this.get = get;
        this.set = set;
        this.assigned = true;
    }
}